import React from 'react'
import Styled from '@emotion/styled'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import breakpoint from '../../utils/breakpoint'
import logo2 from '../../images/logo2.png'

import Text from '../Text'
import ProjectItem from './Projectitem'
import { getWidth } from 'react-slick/lib/utils/innerSliderUtils'

export default () => {
  return (
    <Style>
      <div id='projects' className='wrapper'>
        <div className='head'>
          <img className='logo2' src={logo2} alt='' />
          <br />
          <div className='text'>
            <Text tid='ourPrjectSectionTitle' />
          </div>
        </div>
        <div className='box-projects'>
        <ProjectItem
            tag='new'
            subTag='registerAvailable'
            title='Hi Sukhumvit-Bangchak'
            description={[
              {
                icon: 'price',
                th: '1.5 ล้าน*',
                status: 'hotprice',
                iconprice:'iconhotprice',
                en: 'Start 1.5* million THB'
              },
              {
                icon: 'train',
                th: '5 นาทีจาก BTS บางจาก และ อ่อนนุช',
                en: 'Near BTS Bangchak'
              }
            ]}
            url='https://hicondo9.com/hisukhumvit-bangchak/'
            images={[
              require('../../images/sukhumvit-bangchak/Sukhumvit-bangchak.jpg')
            ]}
          />   
        <ProjectItem
            tag='new'
            subTag='registerAvailable'
            title='Hi Exclusive Ratchada19-Vibhavadi16'
            description={[
              {
                icon: 'price',
                th: 'เริ่มต้นเพียง 1.79* ล้านบาท',
                en: 'Start 1.79* million THB'
              },
              {
                icon: 'train',
                th: '5 นาทีจาก MRT รัชดา',
                en: 'Near MRT Ratchada'
              }
            ]}
            url='https://www.hicondo9.com/hiex19/'
            images={[
              require('../../images/excusive-ratchada-19/HI-Exclusive.jpg')
            ]}
          />  
        <ProjectItem
            tag='new'
            subTag='registerAvailable'
            title='BONA Ngamwongwan - Prachachuen'
            description={[
              {
                icon: 'price',
                th: 'เริ่มต้นเพียง 3.53* ล้านบาท',
                en: 'Start 3.53* million THB'
              },
              {
                icon: 'train',
                th: 'ทาวน์โฮม 2 ชั้น, 4 ห้องนอน 2-3 ห้องน้ำ, 2 ที่จอดรถ',
                en: 'Townhome 2 fl., 4 bedroom'
              }
            ]}
            url='https://hicondo9.com/bonahome/'
            images={[
              require('../../images/bonahome/bona-cover.jpg')
            ]}
          />
          <ProjectItem
            tag='new'
            subTag='registerAvailable'
            title='HI Kaset Senanikom station'
            description={[
              {
                icon: 'price',
                th: '1.5 ล้าน*',
                status: 'hotprice',
                iconprice:'iconhotprice',
                en: '1.5 million THB'
              },
              {
                icon: 'train',
                th: 'เพียง 5 นาที จาก BTS เสนานิคม',
                en: 'Near BTS Rachayothin'
              }
            ]}
            url='https://hicondo9.com/hikaset/'
            images={[
              require('../../images/kaset/Kaset.jpg')
            ]}
          />
          <ProjectItem
            tag='new'
            subTag='registerAvailable'
            title='HI 48 Sutthisan'
            description={[
              {
                icon: 'price',
                th: '1.5 ล้าน*',
                status: 'hotprice',
                iconprice:'iconhotprice',
                en: 'Start at 1.5 million THB'
              },
              {
                icon: 'train',
                th: 'เพียง 5 นาที จาก MRT สุทธิสาร',
                en: 'Near MRT Sutthisan'
              }
            ]}
            url='https://hicondo9.com/hi48sutthisan/'
            images={[
              require('../../images/sutthisan48/Hi-48 Sutthisan.jpg')
            ]}
          />                    
          <ProjectItem
            tag='new'
            subTag='registerAvailable'
            title='HI Rise Ratchayothin Station'
            description={[
              {
                icon: 'price',
                th: 'เริ่มต้น 1.2 ล้าน',
                status: 'hotprice',
                iconprice:'iconhotprice',
                en: '1.2 million THB'
              },
              {
                icon: 'train',
                th: 'เพียง 5 นาที จาก BTS รัชโยธิน',
                en: 'Near BTS Rachayothin'
              }
            ]}
            url='https://hicondo9.com/hirise-ratchayothin/'
            images={[
              require('../../images/ratchayothin/Ratchayothin.jpg')
            ]}
          />
          <ProjectItem
            tag='new'
            subTag='registerAvailable'
            title='HI Rise Sukhumvit 101'
            description={[
              {
                icon: 'price',
                th: '1.5 ล้าน*',
                status: 'hotprice',
                iconprice:'iconhotprice',
                en: 'Start at 1.58 million THB'
              },
              {
                icon: 'train',
                th: 'เพียง 3 นาที จาก BTS ปุณณวิถี',
                en: 'เพียง 3 นาที จาก BTS ปุณณวิถี'
              }
            ]}
            url='https://hicondo9.com/hirise-sukhumvit101/'
            images={[
              require('../../images/sukhumvit101/Sukhumvit-101.jpg')
            ]}
          />
          <ProjectItem
            tag='ready'
            title='Hi Seacon Station'
            description={[
              {
                icon: 'price',
                th: 'เริ่ม 1.49 ล้าน*',
                status: 'hotprice',
                iconprice:'iconhotprice',
                en: '1.49 million THB'
              },
              {
                icon: 'train',
                th: 'เพียง 400 เมตร จาก BTS สายสีเหลือง (ปากซอย ศรีนครินทร์ 42)',
                en: 'Near BTS yellow line (Srinakarin 42)'
              }
            ]}
            url='https://hicondo9.com/hiseacon/'
            images={[
              require('../../images/seacon-station/Seacon.jpg')
            ]}
          />
          <ProjectItem
            tag='ready'
            title='Hi Chaengwattana 19'
            description={[
              {
                icon: 'price',
                th: '1.2 ล้าน*',
                status: 'hotprice',
                iconprice:'iconhotprice',
                en: 'Only 1.2 million THB*'
              },
              {
                icon: 'train',
                th: 'ใกล้รถไฟฟ้าสายสีชมพู และCentral แจ้งวัฒนะ',
                en: 'Near BTS Pink line and Chaengwattana Central'
              }
            ]}
            url='https://hicondo9.com/hichaengwattana19/'
            images={[
              require('../../images/cwt/cwt-01.jpg')
            ]}
          />
          <ProjectItem
            tag='ready'
            title='Hi Sea Dream'
            description={[
              {
                icon: 'price',
                th: 'เริ่ม 1.97 ล้าน*',
                status: 'hotprice',
                iconprice:'iconhotprice',
                en: '1.97 Million THB*'
              },
              {
                icon: 'privilege',
                th: 'หาดส่วนตัว ติดทะเลจริง',
                en: 'Privacy and Peaceful'
              }
            ]}
            url='https://hicondo9.com/seadreamcondo/#home/'
            images={[
              require('../../images/seadream/Seadream.jpg')
            ]}
          />
          <ProjectItem
            tag='ready'
            title='Hi Sukhumvit 93'
            description={[
              {
                icon: 'price',
                th: 'Sold Out',
                status: 'soldout',
                en: 'Sold Out'
              },
              {
                icon: 'train',
                th: 'เพียง 4 นาที จาก BTS บางจาก - อ่อนนุช 24 ',
                en: '4 minutes from BTS Bang Chak-On-Nut 24'
              }
            ]}
            url='https://hicondo9.com/hisukhumvit93/'
            images={[
              require('../../images/sukhumvit/sukhumvit-01.jpg')
            ]}
          />
          <ProjectItem
            tag='ready'
            title='Hi lardprao 130'
            description={[
              {
                icon: 'price',
                th: 'Sold Out',
                status: 'soldout',
                en: 'Sold Out'
              },
              {
                icon: 'train',
                th: 'ใกล้ รถไฟฟ้า สายสีเหลือง-ส้ม และท่าเรือ',
                en: 'Near MRT yellow-orange line and Port'
              }
            ]}
            url='https://hicondo9.com/hiladprao130/'
            images={[
              require('../../images/ladprao130/ladprao-01.jpg')
            ]}
          />
          <ProjectItem
            tag='ready'
            title='Hi Sutthisan'
            description={[
              {
                icon: 'price',
                th: 'Sold Out',
                status: 'soldout',
                en: 'Sold Out'
              },
              {
                icon: 'train',
                th: 'เพียง 5 นาที จาก MRT สุทธิสาร',
                en: '5 minutes from MRT Sutthisan'
              }
            ]}
            url='https://hicondo9.com/hisutthisan'
            images={[
              require('../../images/sutthisan/sutthisan-01.jpg')
            ]}
          />
        </div>
      </div>
    </Style>
  )
}

const Style = Styled.div`
  label: ProjectSection;
  background: #fff;
  padding-top: 0;
  padding-left: 140px;
  padding-right: 40px;
  border-left: 10px solid #344465;

  &::before {
    content: '.';
    width: 0px;
    overflow: hidden;
    opacity: 0;
  }

  .wrapper {
    position: relative;
    background: #fff;
    padding: 45px;
    margin-top: -82px;
  }

  .head {
    text-align: right;
    margin-bottom: 60px;

    > .logo2 {
      display: inline-block;
      width: 60px;
    }
    
    > .text {
      margin-top: 10px;
      font-weight: normal;
      font-size: 20px;
    }
  }

  .box-projects {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -12px;
  }
  
  .project-item {
    width: 33.33%;
    padding: 12px;
    margin-bottom: 50px;

    .slick-list {
      overflow: hidden;
      border-bottom-left-radius: 15px;

      img {
        display: block !important;
        width: 100%;
        outline: none;
      }
    }

    > .title {
      font-weight: bold;
      margin-top: 40px;
      margin-bottom: 10px;
      font-size: 16px;
      text-transform: uppercase;
      cursor: pointer;
    }
    
    > p {
      font-family: "SukhumvitSet";
      font-weight: normal;
    }

    .read-more {
      margin-top: 10px;
      display: inline-block;
      border-bottom: 2px solid #000;
      font-weight: bold;
      cursor: pointer;
    }
  }

  ${breakpoint('T')} {
    border-left: none;
    padding-left: 40px;
    padding-right: 40px;

    .wrapper {
      padding: 40px 0;
    }

    .head {
      text-align: center;
    }
  }

  ${breakpoint('M')} {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 0;

    .head {
      margin-bottom: 30px;
    }

    .project-item {
      width: 50%;
      margin-bottom: 20px;
    }    
  }

  ${breakpoint('540px')} {
    .box-projects {
      margin: 0 -8px;
    }

    .project-item {
      padding: 8px;

      > .title {
        font-size: 14px;
      }
    }
  }
`
