import React, { createContext, useState } from 'react'
import en from './en.json'
import th from './th.json'

export const dictionaryList = { en, th }

export const languageOptions = {
  th: 'THAI',
  en: 'ENG'
}

export const LanguageContext = createContext({
  userLanguage: 'th',
  dictionary: dictionaryList.th
})

export function LanguageProvider({ children }) {
  const [userLanguage, setUserLanguage] = useState('th')

  const provider = {
    userLanguage,
    dictionary: dictionaryList[userLanguage],
    userLanguageChange: (selected) => {
      const newLanguage = languageOptions[selected] ? selected : 'th'
      setUserLanguage(newLanguage)
      window.localStorage.setItem('rcml-lang', newLanguage)
      document.documentElement.setAttribute('lang', selected)
    }
  }

  return <LanguageContext.Provider value={provider}>{children}</LanguageContext.Provider>
}
