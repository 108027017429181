import React, { useState, useRef } from 'react'
import Styled from '@emotion/styled'

import useOnClickOutside from '../hooks/useOnClickOutside'
import popupImage from '../images/popup.jpg'

export default function Popup(params) {
  const popupBodyRef = useRef()
  const [show, setShow] = useState(true)

  const handleClickClose = () => {
    setShow(false)
  }

  useOnClickOutside(popupBodyRef, handleClickClose)

  if (!show) return null

  return (
    <Style>
      <div className='popup'>
        <div className='close'>
          <i className='fas fa-times'></i>
        </div>
        <div className='popup-body' ref={popupBodyRef}>
          <img src={popupImage} alt='' />
        </div>
      </div>
    </Style>
  )
}

const Style = Styled.div`
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgb(0 0 0 / 78%);
    z-index: 99;
    display: flex;
    justify-content: center;
    padding: 20px;
    overflow: auto;
  }

  .popup-body {
    margin-top: 40px;
    max-width: 600px;
    width: 100%;

    img {
      width: 100%;
    }
  }

  .close {
    color: #fff;
    font-size: 30px;
    position: absolute;
    right: 0;
    top: 0;
    padding: 20px;
    cursor: pointer;
  }
`
