import React, { useState, useContext, useRef } from 'react'
import Styled from '@emotion/styled'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { ToastContainer, toast, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { AppContext } from '../../appContext'
import { LanguageContext } from '../../languages'

import Navigation from '../Navigation'
import InputFile from '../InputFile'
import breakpoint from '../../utils/breakpoint'
import logo4 from '../../images/logo4.png'
import logo5 from '../../images/logo5.png'
import bg from '../../images/hc-by-use.png'
import bg2 from '../../images/hc-by-use2.png'

export default () => {
  const { userLanguage, dictionary } = useContext(LanguageContext)
  const appContext = useContext(AppContext)
  const { register, handleSubmit, reset, errors } = useForm()
  const [saving, setSaving] = useState(false)
  const landPictureRef = useRef()
  const locationMapRef = useRef()
  const copyOfTitleDeedRef = useRef()
  const copyOfSellerIdCardRef = useRef()

  const onSubmit = async (data) => {
    setSaving(true)
    console.log(data)
    const formData = new FormData()
    formData.append('fullName', data.fullname)
    formData.append('phone', data.mobileNumber)
    formData.append('mail', data.email)
    formData.append('line', data.lineId)
    formData.append('size', data.landSize)
    formData.append('price', data.offerPrice)
    formData.append('address', data.address)
    formData.append('picture_map', data.locationMap[0])
    formData.append('picture_place', data.landPicture[0])
    formData.append('picture_deed', data.copyOfTitleDeed[0])
    formData.append('picture_id_card', data.copyOfSellerIdCard[0])

    try {
      toast.dark('Submitting...')

      const response = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_HOST}/api/saveSale`,
        data: formData,
        header: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data'
        }
      })

      toast.dismiss()

      if (response.data.statusCode !== 'success') {
        throw new Error(response.data.message)
      }

      toast.success('Success, Thank you.', { autoClose: 5000 })
      setSaving(false)

      reset()
      landPictureRef.current.clear()
      locationMapRef.current.clear()
      copyOfTitleDeedRef.current.clear()
      copyOfSellerIdCardRef.current.clear()
    } catch (error) {
      toast.dismiss()
      toast.error(error.message)
      setSaving(false)
    }
  }

  if (!appContext.showForm) return null

  return (
    <>
      <ToastContainer
        autoClose={false}
        transition={Slide}
        position='top-center'
        hideProgressBar
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      />
      <Style>
        <Navigation />
        <div className='wrapper'>
          <div className='box-info'>
            <img src={logo4} width='60px' alt='' />
            <br />
            {userLanguage === 'th' && (
              <>
                <div className='title'>เสนอขายที่ดิน</div>
                <p>
                  บริษัท ยูทิลิตี้ เรียล เอสเตท จำกัด ประสงค์รับซื้อที่ดินขนาด ตั้งแต่ 2-100 ไร่
                  สำหรับพัฒนาโครงการคอนโดมิเนียม ในพื้นที่กรุงเทพฯ และปริมณฑล นนทบุรี และสมุทรปราการ
                </p>
                <p>
                  ท่านสามารถกรอกข้อมูลออนไลน์ หรือ ท่านสามารถส่งข้อมูลที่ดินมาทางไปรษณีย์
                  ฝ่ายจัดซื้อที่ดิน
                </p>
                <p>
                  บริษัท ยูทิลิตี้ เรียล เอสเตท จำกัด <br /> 227/5 ซ. ประเสริฐมนูกิจ29 แขวงจรเข้บัว
                  เขตลาดพร้าว กรุงเทพฯ 10230
                </p>
                <p>
                  สอบถามข้อมูลเพิ่มเติม ติดต่อ: 02-000-4039 <br /> หรือสามารถติดต่อ
                  ส่งข้อมูลทางไลน์ที่ Line Id: 0809609219
                </p>
              </>
            )}

            {userLanguage === 'en' && (
              <>
                <div className='title'>LAND FOR SALE</div>
                <p>
                  Utility Real Estate Co.,ltd afford to purchase 2-100 Rai land. (1 Rai=1,600 square
                  metres) for develop condominium project in Bangkok and perimetre (Nonthaburi or
                  Samut Prakan)
                </p>
                <p>
                  Please fill up this form or send land detail to our Land Purchasing official
                  address.
                </p>
                <p>
                  Utility Real Estate Co.,ltd. <br /> 227/5 Prasert-Manukitch 29, Chorakhe Bua,
                  Lardprao, Bangkok 10230
                </p>
                <p>
                  Tel: 02-000-4039 <br /> Line Id: 0809609219
                </p>
              </>
            )}
            <br />
            <img src={logo5} width='100px' alt='' />
            <br />
            <br />
            <br />
            <img src={bg2} width='100%' alt='' />
          </div>
          <div className='box-form'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='field'>
                <input
                  type='text'
                  name='fullname'
                  placeholder={dictionary['fullname']}
                  ref={register({ required: true })}
                />
                {errors.fullname && <div className='field-error'>Field is required</div>}
              </div>

              <div className='field'>
                <input
                  type='tel'
                  name='mobileNumber'
                  placeholder={dictionary['mobileNumber']}
                  ref={register({ required: true, minLength: 10, maxLength: 10 })}
                />
                {errors.mobileNumber && <div className='field-error'>Field is required</div>}
              </div>

              <div className='field'>
                <input
                  type='email'
                  name='email'
                  placeholder={dictionary['email']}
                  ref={register({ required: true, pattern: /^\S+@\S+$/i })}
                />
                {errors.email && <div className='field-error'>Field is required</div>}
              </div>

              <div className='field'>
                <input
                  type='text'
                  name='lineId'
                  placeholder={dictionary['lineId']}
                  ref={register({ required: true })}
                />
                {errors.lineId && <div className='field-error'>Field is required</div>}
              </div>

              <div className='field'>
                <input
                  type='text'
                  name='offerPrice'
                  placeholder={dictionary['offerPrice']}
                  ref={register({ required: true })}
                />
                {errors.offerPrice && <div className='field-error'>Field is required</div>}
              </div>

              <div className='field'>
                <input
                  type='text'
                  name='landSize'
                  placeholder={dictionary['landSize']}
                  ref={register({ required: true })}
                />
                {errors.landSize && <div className='field-error'>Field is required</div>}
              </div>

              <div className='field'>
                <textarea
                  name='address'
                  placeholder={dictionary['addressLabel']}
                  ref={register({ required: true })}
                  rows='5'
                ></textarea>
                {errors.address && <div className='field-error'>Field is required</div>}
              </div>

              <div className='field'>
                <InputFile
                  ref={{
                    input: register({ required: true }),
                    state: landPictureRef
                  }}
                  name='landPicture'
                  placeholder={dictionary['landPicture']}
                />
                {errors.landPicture && <div className='field-error'>Field is required</div>}
              </div>

              <div className='field'>
                <InputFile
                  ref={{
                    input: register({ required: true }),
                    state: locationMapRef
                  }}
                  name='locationMap'
                  placeholder={dictionary['locationMap']}
                />
                {errors.locationMap && <div className='field-error'>Field is required</div>}
              </div>

              <div className='field'>
                <InputFile
                  ref={{
                    input: register({ required: true }),
                    state: copyOfTitleDeedRef
                  }}
                  name='copyOfTitleDeed'
                  placeholder={dictionary['copyOfTitleDeed']}
                />
                {errors.copyOfTitleDeed && <div className='field-error'>Field is required</div>}
              </div>

              <div className='field'>
                <InputFile
                  ref={{
                    input: register({ required: true }),
                    state: copyOfSellerIdCardRef
                  }}
                  name='copyOfSellerIdCard'
                  placeholder={dictionary['copyOfSellerIdCard']}
                />
                {errors.copyOfSellerIdCard && <div className='field-error'>Field is required</div>}
              </div>

              <button className='btn-submit' disabled={saving} type='submit'>
                {saving ? 'Submitting...' : 'Submit'}
              </button>
            </form>
          </div>
        </div>
      </Style>
    </>
  )
}

const Style = Styled.div`
  label: WeBuyForm;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  border-left: 10px solid #003bad;

  .wrapper {
    display: flex;
    align-items: flex-start;
    flex-grow: 1;
    position: relative;
    background: #344465;
    overflow: auto;
    overflow-x: hidden;
    font-family: 'SukhumvitSet';
    font-weight: normal;
    font-size: 13px;
    color: #fff;
    padding: 100px;
  }

  .title {
    font-size: 20px;
    margin-top: 10px;
  }

  p {
    line-height: 1.61;
    margin-bottom: 26px;
  }

  .box-info {
    width: 100%;
    max-width: 330px;
  }

  .box-form {
    width: 100%;
    max-width: 440px;
    margin-left: 120px;
    position: relative;

    &:after {
      content: '';
      flex-grow: 1;
      position: absolute;
      width: 400px;
      height: 100%;
      top: 0;
      right: 0;
      transform: translateX(110%);
      background: #344465 url(${bg}) center right no-repeat;
      background-size: 100%;
    }
  }

  .field {
    margin-bottom: 20px;
    
    .field-error {
      margin-top: 10px;
      color: #ffae14;
      text-align: left;
    }
  }

  input, textarea {
    width: 100%;
    border: none;
    outline: none;
    padding: 10px;
    border-bottom-left-radius: 10px;
    background: #fff;
    color: #000;
  }

  .btn-submit {
    width: 100%;
    border: 3px solid #fff;
    border-bottom-left-radius: 10px;
    background: none;
    padding: 10px 15px;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
  }

  ${breakpoint('1000px')} {
    .wrapper {
      display: block;
    }

    .box-form {
      margin-left: 0;
      margin-top: 40px;
    }
  }

  ${breakpoint('725px')} {
    border-left: none;

    .box-info, .box-form {
      max-width: 100%;
    }

    .wrapper {
      text-align: center;
      /* padding: 40px 20px; */

      img {
        display: inline-block;
      }
    }
  }

  ${breakpoint('540px')} {
    .wrapper {
      padding: 80px 40px;
    }
  }

  ${breakpoint('SM')} {
    .wrapper {
      padding: 80px 20px;
    }
  }
`
