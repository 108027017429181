import React from 'react'
import { Global } from '@emotion/core'

import { AppContextProvider } from './appContext'
import { LanguageProvider } from './languages'
import globalStyles from './globalStyles'
import Home from './components/Home'
import AboutUsSection from './components/AboutUsSection'
import ProjectSection from './components/ProjectSection'
import ContactSection from './components/ContactSection'
import WeBuyLandForm from './components/WeBuyLandForm'
import HiContent from './components/HiContent'
import Popup from './components/Popup'

function App() {
  return (
    <AppContextProvider>
      <LanguageProvider>
        <Global styles={globalStyles} />
        <Popup />
        <Home />
        <AboutUsSection />
        <ProjectSection />
        <ContactSection />
        <WeBuyLandForm />
        <HiContent />
      </LanguageProvider>
    </AppContextProvider>
  )
}

export default App
