import React, { useState, useRef, useContext } from 'react'
import Styled from '@emotion/styled'

import goNavTo from '../../utils/goNavTo'
import breakpoint from '../../utils/breakpoint'
import LanguageSelector from '../LanguageSelector'
import Text from '../Text'
import { AppContext } from '../../appContext'
import { LanguageContext } from '../../languages'
import useOnClickOutSide from '../../hooks/useOnClickOutside'

export default () => {
  const { userLanguage } = useContext(LanguageContext)
  const appContext = useContext(AppContext)
  const [show, setShow] = useState(false)
  const navigationRef = useRef()

  useOnClickOutSide(navigationRef, () => setShow(false))

  const handleClickNav = (goTo) => {
    setShow(false)
    goNavTo(goTo)
    appContext.set({ key: 'showForm', value: false })
  }

  const handleClickShowForm = () => {
    appContext.set({ key: 'showForm', value: true })
    setShow(false)
  }

  const goto = (url) => {
    // window.open(url)
    window.location = url;
  }

  return (
    <Style id='top' lang={userLanguage} show={show}>
      <div className='btn-open-navigation' onClick={() => setShow(true)}>
        <div className='line'></div>
        <div className='line'></div>
        <div className='line'></div>
      </div>
      <div className='box-navigation' ref={navigationRef}>
        <LanguageSelector />
        <div className='nav-group'>
          <div className='nav-item' onClick={() => handleClickNav('about-us')}>
            <Text tid='aboutUs' />
          </div>
          <div className='nav-item' onClick={() => handleClickNav('projects')}>
            <Text tid='ourProjects' />
          </div>
          <div className='nav-item' onClick={() => handleClickNav('contact-us')}>
            <Text tid='contactUs' />
          </div>
          <div className='nav-item' onClick={handleClickShowForm}>
            <Text tid='weBuyLand' />
          </div>
          <div className='nav-item' onClick={handleClickShowForm}>
            <Text tid='HiContect' />
          </div>
          <div className='nav-item' onClick={() => goto('/hi-history/')}>
            <Text tid='Hi History' />
          </div>
        </div>
      </div>
    </Style>
  )
}

const Style = Styled.div`
  label: Navigation;

  font-size: 12px;
  
  .box-navigation {
    color: #fff;
    background-color: #000514;
    width: 180px;
    padding: 30px;
    height: 100%;
  }
  
  .btn-open-navigation {
    display: none;
    position: fixed;
    top: 15px;
    right: 20px;
    z-index: 3;
    width: 45px;
    cursor: pointer;
    padding: 5px 10px;
    background: rgb(0 0 0 / 50%);
    border-radius: 10px;

    > .line {
      background: #fff;
      height: 3px;
      margin: 5px 0;
    }
  }

  .nav-group {
    margin-top: 80px;
    font-weight: bold;
  }

  .nav-item {
    display: flex;
    align-items: center;
    padding: 13px 0;
    text-transform: uppercase;
    cursor: pointer;
  }

  ${breakpoint('725px')} {
    .box-navigation {
      position: fixed;
      z-index: 4;
      top: 0;
      right: 0;
      bottom: 0;
      border-bottom-left-radius: 15px;
      transform: ${({ show }) => (show ? 'translate3d(0%, 0, 0)' : 'translate3d(100%, 0, 0)')};
      transition: all 0.3s ease-in-out;
    }

    .btn-open-navigation {
      display: block;
    }
  }

  ${breakpoint('400px')} {
    
  }
`
