import React from 'react'
import Styled from '@emotion/styled'

import Navigation from '../Navigation'
import SocialMedia from '../SocialMedia'
import breakpoint from '../../utils/breakpoint'
import homeBannerMobile from '../../images/home-mobile.jpg'
import logo from '../../images/logo.png'
import people from '../../images/people.png'
import videoBG from '../../video/hicondo-video-2.mp4'

import Text from '../Text'

export default () => {
  return (
    // <Style>
    //   <Navigation />
    //   <div className='wrap-banner'>
    //     <SocialMedia />
        // <div className='box-logo'>
        //   <img src={logo} alt='' />
        //   <div className='text'>
        //     <Text tid='title' />
        //   </div>
        // </div>
        // <div className='caption'>
        //   <h1>
        //     {/* <span>LIFE DESIGN AT</span> <br /> */}
        //     “Happiness is all around”
        //   </h1>
        //   <p>
        //     <Text tid='homeDescription' />
        //   </p>
        // </div>
    //     <div className='box-banner'></div>
    //   </div>
    // </Style>
    <Style>
      <Navigation />
      <div className='wrap-banner'>
        <SocialMedia />
        <div id='left-box'>
        <div className='box-logo'>
          <img src={logo} alt='' />
          <div className='text'>
            <Text tid='title' />
          </div>
        </div>
        <div className='caption'>
          <h1>
            {/* <span>LIFE DESIGN AT</span> <br /> */}
            “Happiness is all around”
          </h1>
          <p>
            <Text tid='homeDescription' />
          </p>
          <h2>
            {/* <span>LIFE DESIGN AT</span> <br /> */}
            เริ่มต้นชีวิตดีดี ใช้เงินอย่างคุ้มค่าที่ HI
          </h2>
        </div>
        {/* <video autoplay="autoplay" controls="controls" loop muted class="fullscreen" src={homeBannerMobile}></video> */}
        {/* <div className='box-banner'></div> */}
        <video id="video-bg" class="fullscreen" loop playsInline autoplay="autoPlay" muted>
          <source src={videoBG} type="video/mp4"></source>
          Your browser does not support HTML5 video.
        </video>
        </div>         
      </div>
      <script>
        var vid = document.getElementById("video-bg");
        vid.muted = true;
      </script>
    </Style>
  )
}

const Style = Styled.div`
  label: Home;
  display: flex;
  border-left: 10px solid #344465;

  @media only screen and (min-width: 1080px) {
    #left-box .box-logo{
      right: 20%;
      .text{
        font-size:30px;
        font-weight: bold;
      }
      img{
        height: 100px;
      }
    }
    #left-box .caption{
      left:30% !important;
      top:50%;
      p{
        color:#000 !important;
        background-color: rgb(255,255,255,0.5);
        padding: 1em;
        text-shadow:0 0 black;
        font-size: 16px;
        width:580px!important;
      },
      h1{
        width:580px!important;
      },
      h2{
        font-size: 25px;
        width:580px!important;
        font-family: "SukhumvitSet";
        text-transform: uppercase;
        color: #e7cb71;
      }
    }
  }
  
  .social-media {
    position: relative;
    z-index: 1;
    color: #fff;
    
    .social-media-link {
      color: #fff;
    }
  }

  .wrap-banner {
    flex-grow: 1;
    position: relative;
    height: 100vh;
    overflow: hidden;
    border-bottom-left-radius: 15px;
  }

  .box-banner {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 0;
    overflow: hidden;
    border-bottom-left-radius: 15px;
    background: #353d47 url(${homeBannerMobile}) top center no-repeat;
    background-size: cover;
    // animation: zoom 10s linear forwards;
    width: 100%;

    &::before {
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
      content: '';
      // background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(84, 84, 84, 0));
    }
  }

  video.fullscreen {
    position: absolute;
    z-index: 0;
    object-fit: cover;
    width:100%;
    height:100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  
    &::-webkit-media-controls {
       display:none !important;
    }
  }

  .caption {
    position: absolute;
    z-index: 1;
    bottom: 120px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: #fff!important;
    text-shadow: 3px 3px 6px #000000;
    font-size: 16px;
    width: 520px;

    h1 {
      font-size: 40px;
      font-family: "SukhumvitSet";
      text-transform: uppercase;
      color: #e7cb71;

      > span {
        font-size: 22px;
        color: #fff;
      }
    },
    h2 {
      font-size: 18px;
      font-family: "SukhumvitSet";
      text-transform: uppercase;
      color: #e7cb71;
      > span {
        font-size: 22px;
        color: #fff;
      }
    }

  }

  .box-logo {
    position: relative;
    z-index: 1;
    margin: 90px auto 0;

    > img {
      display: block;
      margin: 0 auto;
      height: 65px;
    }

    > .text {
      text-align: center;
      text-shadow: 3px 3px 6px #000000;
      color: #fff;
      font-size: 14px;
      font-weight: normal;
      font-style: italic;
    }
  }

  ${breakpoint('725px')} {
    border-left: 0;

    .social-label {
      display: none !important;
    }

    /* .box-banner {
      background: #353d47 url(${homeBannerMobile}) top left no-repeat;
      background-size: cover;
      animation: slide 5s 1s linear forwards;

      &::after {
        position: absolute;
        z-index: 0;
        bottom: 60px;
        left: -16px;
        right: 0;
        top: 50%;
        display: block;
        content: '';
        background: transparent url(${people}) top center no-repeat;
        background-size: cover;
      }
    } */
  }

  ${breakpoint('540px')} {
    .caption {
      font-size: 14px;
      width: 280px;
      bottom: 150px;
      top: 40%;

      h1,h2 {
        font-size: 30px;
        font-family: "SukhumvitSet";

        > span {
          font-size: 18px;
        }
      }
    }
  }

  @keyframes slide {
    from {
      background-position: 20% 0%
    }
    to { 
      background-position: 60% 0%
    }
  }

  @keyframes zoom {
    from {
      transform: scale(1.3);
    }
    to { 
      transform: scale(1);
    }
  }
`
