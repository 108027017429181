import React, { useRef, useContext } from 'react'
import Styled from '@emotion/styled'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import breakpoint from '../../utils/breakpoint'
import { LanguageContext } from '../../languages'
import sprite from '../../images/sprite.svg'
import spritehotprice from '../../images/hot-price-2.gif'
import Text from '../Text'

export default (props) => {
  const { tag, title, images, subTag, description, url } = props

  const { userLanguage } = useContext(LanguageContext)
  const slider = useRef()
  let sliderAuto = null

  const goto = (url) => {
    window.open(url)
  }

  const handleMouseOver = () => {
    slider.current.slickNext()
    sliderAuto = setInterval(() => slider.current.slickNext(), 3000)
  }

  const handleMouseLeave = () => {
    clearInterval(sliderAuto)
  }

  const renderSubTag = () => {
    if (!subTag) return null
    if (subTag === 'inprogress') {
      return (
        <div className='sub-tag'>
          <Text tid='inprogress' />
        </div>
      )
    }
    if (subTag === 'registerAvailable') {
      return (
        <div className='sub-tag'>
          <Text tid='registerAvailable' />
        </div>
      )
    }

    return <div className='sub-tag'>{subTag}</div>
  }

  const renderTag = () => {
    if (!tag) return null
    if (tag === 'new')
      return (
        <>
          <div className='box-tag new'>
            <Text tid='newProject' />
          </div>
          {renderSubTag()}
        </>
      )
    if (tag === 'ready')
      return (
        <>
          <div className='box-tag ready'>
            <Text tid='moveInReady' />
          </div>
          {renderSubTag()}
        </>
      )
  }

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    // autoplaySpeed: 3000,
    // fade: true,
    // autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1
  }

  return (
    <Style>
      <div onMouseEnter={handleMouseOver} onMouseLeave={handleMouseLeave}>
        <Slider ref={slider} {...settings}>
          {images.map((img, i) => (
            <img key={i} src={img} alt='' />
          ))}
        </Slider>
      </div>

      <div className='title'>
        {renderTag()} <br />
        {title}
      </div>
      {description.map((item, i) => (
        <div className='project-detail-item'>
          <div className={`icon ${item.icon}`}></div>
          <span className={`${item.status}`}>{item[userLanguage]}</span>
          <div className={`${item.iconprice}`}></div>
        </div>
      ))}
      <div className='read-more' onClick={() => goto(url)}>
        {userLanguage === 'th' ? 'ดูรายละเอียดโครงการ' : 'See Preject Detail'}
      </div>
    </Style>
  )
}

const Style = Styled.div`
  label: ProjectItem;

  width: 33.33%;
  padding: 12px;
  margin-bottom: 50px;

  span.hotprice{
      color: red!important;
  }
  span.soldout{
      font-size: 20px;
  }

  .slick-list {
    overflow: hidden;
    border-bottom-left-radius: 15px;

    img {
      display: block !important;
      width: 100%;
      outline: none;
    }
  }

  > .title {
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 10px;
    font-size: 16px;
    text-transform: uppercase;
  }
  
  > p {
    font-family: "SukhumvitSet";
    font-weight: normal;
  }

  .read-more {
    margin-top: 10px;
    display: inline-block;
    border-bottom: 2px solid #000;
    font-weight: bold;
    cursor: pointer;
  }

  .box-tag {
    display: inline-block;
    margin-bottom: 10px;
    padding: 5px 10px;
    font-size: 12px;
    color: #fff;
    border-bottom-left-radius: 10px;

    &.new {
      background: red;
    }

    &.ready {
      background: #003bad;
    }
  }

  .sub-tag {
    margin-left: 10px;
    display: inline-block;
    font-size: 12px;
    color: #666;
  }

  .project-detail-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    .icon {
      margin-right: 5px;
    }
  }

  .icon {
    flex-shrink: 0;
    background-image: url(${sprite});
    background-repeat: no-repeat;
    display: block;
    width: 30px;
    height: 30px;
    background-position-y: -250px;

    &.price {
      background-position-x: -60px;
    }

    &.privilege {
      background-position-x: -180px;
    }
  }
  .iconhotprice{
    flex-shrink: 0;
    background-image: url(${spritehotprice});
    display: block;
    margin-left:10px;
    width: 60px;
    height: 55px;
  }

  ${breakpoint('T')} {
    
  }

  ${breakpoint('M')} {
    width: 50%;
    margin-bottom: 20px;
  }

  ${breakpoint('540px')} {
    padding: 8px;

    > .title {
      font-size: 14px;
    }
  }
`
