import React, { useEffect, useContext } from 'react'
import Styled from '@emotion/styled'
import { LanguageContext, languageOptions } from '../languages'

export default () => {
  const { userLanguage, userLanguageChange } = useContext(LanguageContext)

  const handleLanguageChange = (lang) => userLanguageChange(lang)

  useEffect(() => {
    let defaultLanguage = window.localStorage.getItem('rcml-lang')
    if (!defaultLanguage) {
      // defaultLanguage = window.navigator.language.substring(0, 2)
      defaultLanguage = userLanguage
    }
    // userLanguageChange(defaultLanguage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Style>
      {Object.entries(languageOptions).map(([id, name]) => (
        <div className='lang-label' key={id} onClick={() => handleLanguageChange(id)}>
          {name}
        </div>
      ))}
    </Style>
  )
}

const Style = Styled.div`
  label: LanguageSelector;
  
  display: flex;

  .lang-label {
    cursor: pointer;
    font-weight: bold;

    &:not(:first-child) {
      &::before {
        display: inline-block;
        margin: 0 3px;
        content: '/';
      }
    }
  }
`
